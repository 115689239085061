import { Token } from "@/lib/entities/token.entity";

export const useTokenIconSvg = (token: Token) => {
  if (!token) return "";
  switch (token.symbol) {
    case "pfastUSD":
      return "/icons/tokens/pfastusd.svg";
    default:
      return token?.logo || "";
  }
};