import {
  FC,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { TokenBalance } from "@/lib/entities/balance.entity";
import { useApproveToken } from "@/hooks/wallet/useApproveToken";
import {
  chakra,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  useColorModeValue,
  Image,
  Text,
} from "@chakra-ui/react";
import { useBalances, useSingleBalance } from "@/hooks/wallet/BalanceProvider";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { ethers } from "ethers";
import useIsMobile from "@/hooks/useIsMobile";

type Props = ButtonProps & {
  children: ReactNode;
  isLoading?: boolean;
  loadingText?: string;
  containerProps?: BoxProps;
  isLoadingButton?: boolean;

  executedData?: {
    balance: TokenBalance;
    contractAddress: string;
    executedAmount: AppNumber;
  };

  onClick: (e?: MouseEventHandler<HTMLButtonElement>) => void;
};

export const Web3ActionButton: FC<Props> = ({
  children,
  isLoading,
  isLoadingButton,
  containerProps,
  loadingText,
  executedData,
  isDisabled,
  onClick,
  ...rest
}) => {
  const { rpcSigner } = useSigner();
  const { open: openWeb3Modal } = useWeb3Modal();
  const { getBalances, isFetching } = useBalances();
  const balance = useSingleBalance(
    executedData?.balance?.tokenInfo?.contractAddress ?? ""
  );

  const isMobile = useIsMobile();

  const [isApprovedShown, setIsApprovedShown] = useState(false);
  const [isApartApproved, setIsApartApproved] = useState(false); // Present when user approved the token with amount more than executed amount
  const [isAllApproved, setIsAllApproved] = useState(false); // Present when user approved all

  const {
    approve,
    isPending: isPendingApprove,
    isSuccess: isSuccessApprove,
  } = useApproveToken();

  const [approvedOptionHoverIndex, setApprovedOptionHoverIndex] = useState(-1);

  const decimals = executedData?.balance?.tokenInfo?.decimals ?? 1;

  const allowanceBalance = AppNumber.from(
    balance?.allowances?.[executedData.contractAddress]?.toBigNumber() ?? 0n
  );
  const executedAmount =
    executedData?.executedAmount?.getRealTokenAmountWithDecimals(decimals) ??
    AppNumber.from(0);

  const handleApprove = useCallback(
    (amount: AppNumber) => {
      approve({
        tokenAddress: executedData?.balance.tokenInfo?.contractAddress ?? "",
        spenderAddress: executedData?.contractAddress,
        approvedAmount: amount,
      });
    },
    [approve, executedData]
  );

  useEffect(() => {
    if (balance?.tokenInfo?.isGasToken) {
      return setIsApprovedShown(false);
    }

    if (!executedData || balance?.tokenInfo?.isGasToken || !rpcSigner?.address)
      return;

    console.log(
      "Allowance",
      allowanceBalance.toBigNumber(),
      "Executed Amount",
      executedAmount.toBigNumber()
    );  

    setIsApprovedShown(true);

    // if (!allowanceBalance.gt(AppNumber.from(0))) {
    //   return;
    // }

    if (allowanceBalance.gte(AppNumber.from(ethers.MaxInt256))) {
      setIsAllApproved(true);
    } else {
      setIsAllApproved(false);
    }

    if (allowanceBalance.gte(executedAmount)) {
      setIsApartApproved(true);
    } else {
      console.log("revert apart approved");
      setIsApartApproved(false);
    }
  }, [executedData, rpcSigner, balance, allowanceBalance, executedAmount]);

  useEffect(() => {
    if (isSuccessApprove) {
      getBalances();
    }
  }, [isSuccessApprove]);

  const loadingTextButton = isPendingApprove
    ? "Approving..."
    : !isFetching
      ? loadingText
      : "";

  const _isLoadingButton = isPendingApprove || isFetching || isLoadingButton;

  const isDisabledButtonAllowance = rpcSigner?.address
    ? isAllApproved
      ? false
      : !isApartApproved
    : true;

  return (
    <Flex flexDirection="column" gap="20px">
      {isApartApproved &&
        !isAllApproved &&
        !balance?.tokenInfo?.isGasToken &&
        !isDisabled && (
          <Button
            display="flex"
            gap={2}
            loadingText={loadingTextButton}
            isLoading={_isLoadingButton}
            position="relative"
            isDisabled={true}
            _disabled={{
              bg: useColorModeValue("brand.light.shade", "brand.supper.light"),
              opacity: 1,
            }}
            {...rest}
          >
            <Flex
              float="right"
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="100%"
              gap="8px"
            >
              <Image src="/icons/status/success.svg" w="24px" h="24px" />
              <chakra.span color="accent.success">{`Approved ${executedData?.balance.tokenInfo?.symbol}`}</chakra.span>
            </Flex>
          </Button>
        )}
      {isApprovedShown && !isAllApproved && !isApartApproved && (
        <Skeleton isLoaded={!isLoading} {...containerProps}>
          <Popover placement={isMobile ? "bottom-end" : "right-end"}>
            <PopoverTrigger>
              <Button
                display="flex"
                gap={2}
                loadingText={loadingTextButton}
                isLoading={_isLoadingButton}
                position="relative"
                isDisabled={isDisabled}
                {...rest}
              >
                <Flex
                  float="right"
                  display="flex"
                  justifyContent="center"
                  w="100%"
                >
                  <chakra.span>{`Approve ${executedData?.balance.tokenInfo?.symbol}`}</chakra.span>
                </Flex>
                <Flex
                  float="left"
                  w="40px"
                  gap="8px"
                  alignItems="center"
                  justifyContent="center"
                  borderLeft="1px solid"
                  borderColor={useColorModeValue(
                    "neutral.element.tertiary_light_theme",
                    "neutral.element.tertiary2"
                  )}
                  position="absolute"
                  height="100%"
                  right={0}
                  top={0}
                >
                  <Image
                    src={`/icons/chevron/down/${useColorModeValue("dark", "light")}.svg`}
                    w="24px"
                    h="24px"
                  />
                </Flex>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverBody
                  display="flex"
                  flexDirection="column"
                  gap="24px"
                  w="100%"
                  h="318px"
                  bg={useColorModeValue("white", "neutral.2")}
                  shadow="md"
                  boxShadow="2xl"
                  borderRadius="12px"
                  border="1px solid"
                  borderColor={useColorModeValue(
                    "neutral.stroke.bold_light_theme",
                    "neutral.stroke.bold"
                  )}
                  marginLeft={{ base: "10px", lg: 0 }}
                >
                  {[0, 1].map((index) => (
                    <Flex
                      p="16px"
                      borderRadius="16px"
                      gap="8px"
                      cursor="pointer"
                      onMouseMove={() =>
                        !_isLoadingButton && setApprovedOptionHoverIndex(index)
                      }
                      onMouseLeave={() =>
                        !_isLoadingButton && setApprovedOptionHoverIndex(-1)
                      }
                      onClick={() =>
                        !_isLoadingButton
                          ? index
                            ? handleApprove(AppNumber.from(ethers.MaxInt256))
                            : handleApprove(
                                executedData?.executedAmount.add(AppNumber.from(0.01))?.getRealTokenAmountWithDecimals(
                                  executedData?.balance?.tokenInfo?.decimals ??
                                    1
                                )
                              )
                          : null
                      }
                      _hover={{
                        bg: useColorModeValue(
                          "neutral.stroke.light_theme",
                          "neutral.stroke.light"
                        ),
                      }}
                    >
                      <Image
                        src="/icons/status/success.svg"
                        w="20px"
                        h="20px"
                        visibility={
                          approvedOptionHoverIndex === index
                            ? "visible"
                            : "hidden"
                        }
                      />
                      <Flex flexDirection="column" gap="8px">
                        <Text size="xl" fontWeight="bold">
                          Approve {executedData?.balance?.tokenInfo?.symbol}
                        </Text>
                        <Text size="md" colorScheme="secondary">
                          {index
                            ? `You won’t need to approve again next time you want to spend ${executedData?.balance?.tokenInfo?.symbol}.`
                            : `You’ll give your approval to spend ${
                                executedData?.executedAmount?.getDisplayedString() ??
                                0
                              } ${executedData?.balance?.tokenInfo?.symbol} on your behalf.`}
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Skeleton>
      )}
      <Skeleton isLoaded={!isLoading} {...containerProps}>
        <Button
          display="flex"
          justifyContent="center"
          gap={2}
          onClick={(e) => {
            if (rpcSigner?.address) return onClick(e);
            openWeb3Modal({ view: "Wallet" } as any)
              .then(() => console.info("resolved"))
              .catch(() => console.info("rejected"));
          }}
          loadingText={loadingTextButton}
          isLoading={_isLoadingButton}
          isDisabled={
            rpcSigner?.address
              ? isDisabled || (isDisabledButtonAllowance && isApprovedShown)
              : false
          }
          {...rest}
        >
          {rpcSigner?.address ? children : "Connect Wallet"}
        </Button>
      </Skeleton>
    </Flex>
  );
};
